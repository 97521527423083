




import {Vue, Component, Prop} from 'vue-property-decorator'
import {VehicleSlot} from '@/lib/kepler/interfaces'
import VehicleAvatarWithArc from '@/components/VehicleAvatarWithArc.vue'

@Component({
  components: {
    VehicleAvatarWithArc,
  },
  name: 'VehicleSlotAvatar',
})
export default class VehicleSlotAvatar extends Vue {
  @Prop({type: Object, required: true}) protected vs!: VehicleSlot
  @Prop({type: Boolean, default: false}) protected small!: boolean
  @Prop({type: Boolean, default: false}) protected arc!: boolean

  protected get params() {
    const type = this.vs.vehicle.category.type
    return {
      type,
      color: (this.vs.reservation_type + type).toUpperCase(),
      level: this.arc ? this.vs.vehicle.fuel_level || 0 : -1,
      small: this.small,
    }
  }
}
