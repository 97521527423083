













import {Component, Prop} from 'vue-property-decorator'
import VehicleAvatar from '@/components/VehicleAvatar.vue'
import arc from '@/lib/svg-arc'
import VuetifyColorHelper from '@/lib/vuetify/VuetifyColorHelper'

@Component({
  components: {VehicleAvatar},
  name: 'VehicleAvatarWithArc',
})
export default class VehicleAvatarWithArc extends VehicleAvatar {
  @Prop({type: Number, required: true}) protected level!: number

  protected get lightClass() {
    const c = VuetifyColorHelper.lightContrast(VuetifyColorHelper.color(this.color))
    return `v-icon ${c ? 'theme--light' : 'theme--dark'}`
  }

  protected get svg() {
    const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg')
    svg.setAttribute('viewBox', '0 0 300 300')
    const path = document.createElementNS('http://www.w3.org/2000/svg', 'path')
    svg.appendChild(path)
    const d = arc({x: 150, y: 150, R: 120, r: 120, start: 0, end: (this.level || 0) / 100 * 360})
    path.setAttribute('d', d)
    path.setAttribute('fill-rule', 'evenodd')
    path.setAttribute('stroke-linejoin', 'round')
    path.setAttribute('stroke-linecap', 'round')
    path.setAttribute('stroke', 'currentColor')
    path.setAttribute('stroke-width', '25')
    return svg
  }
}
