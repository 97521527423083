











import {Vue, Component, Prop} from 'vue-property-decorator'
import VuetifyColorHelper from '@/lib/vuetify/VuetifyColorHelper'
import Utils from '@/utils'
import {VehicleType} from '@/lib/kepler/interfaces'

@Component({
  components: {
    VehicleIcon: Utils.loadComponent('VehicleIcon'),
    Avatar: Utils.loadComponent('proxy/Avatar'),
  },
  name: 'VehicleAvatar',
})
export default class VehicleAvatar extends Vue {
  @Prop({type: String, required: true}) protected type!: VehicleType
  @Prop({type: Boolean, default: false}) protected small!: boolean
  @Prop({type: String, default: () => 'accent'}) protected color!: string

  protected get isLight() {
    return VuetifyColorHelper.lightContrast(VuetifyColorHelper.color(this.color))
  }

}
